import React from "react";
import "./src/styles/global.css";

import { GlobalProvider } from "./src/contexts/GlobalContext";

import type { GatsbyBrowser } from "gatsby";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <GlobalProvider>{element}</GlobalProvider>;
};
